import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_DGA_SM,
  UPSERT_DGA_SM,
  GET_ALL_DR,
  GET_ALL_DATAPOINT_QUERY,
  UPSERT_DR,
  GET_ALL_MATURITY_LEVEL_DGA,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (method, url, data, token, rejectWithValue) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    return rejectWithValue(
      error.response?.data?.message ||
      error.response?.data?.title ||
      error?.message ||
      "An error occurred during the API request"
    );
  }
};

// Async thunks
export const getDGASustainabilityMatters = createAsyncThunk(
  "dgaSustainabilityMatters/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DGA_SM}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDGADisclosureRequirements = createAsyncThunk(
  "getDGADisclosureRequirements/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DR}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getDGADataPointQuery = createAsyncThunk(
  "dgaDataPointQuery/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_DATAPOINT_QUERY}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const upsertDGASustainabilityMatters = createAsyncThunk(
  "dgaSustainabilityMatters/upsert",
  async ({ sustainabilityMattersData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_DGA_SM,
      sustainabilityMattersData,
      token,
      rejectWithValue
    );
  }
);

export const upsertDisclosureData = createAsyncThunk(
  "dgaDisclosure/upsert",
  async ({ disclosureData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_DR,
      disclosureData,
      token,
      rejectWithValue
    );
  }
);

export const getMaturityLevels = createAsyncThunk(
  "dgaMaturityLevels/getAll",
  async ({ token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      GET_ALL_MATURITY_LEVEL_DGA,
      null,
      token,
      rejectWithValue
    );
  }
);

// Initial state
const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
  updateSuccess: false,
  postSuccess: false,
  types: [],
};

// Common reducer functions
const handlePending = (state) => {
  state.isLoading = true;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = true;
  state.postSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

// Slice definition
const dgaSustainabilityMattersSlice = createSlice({
  name: "dgaSustainabilityMatters",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDGASustainabilityMatters.pending, handlePending)
      .addCase(getDGASustainabilityMatters.fulfilled, handleFulfilled)
      .addCase(getDGASustainabilityMatters.rejected, handleRejected)
      .addCase(getDGADisclosureRequirements.pending, handlePending)
      .addCase(getDGADisclosureRequirements.fulfilled, handleFulfilled)
      .addCase(getDGADisclosureRequirements.rejected, handleRejected)
      .addCase(getDGADataPointQuery.pending, handlePending)
      .addCase(getDGADataPointQuery.fulfilled, handleFulfilled)
      .addCase(getDGADataPointQuery.rejected, handleRejected)
      .addCase(upsertDGASustainabilityMatters.pending, handlePending)
      .addCase(upsertDGASustainabilityMatters.fulfilled, handleFulfilled)
      .addCase(upsertDGASustainabilityMatters.rejected, handleRejected)
      .addCase(upsertDisclosureData.pending, handlePending)
      .addCase(upsertDisclosureData.fulfilled, handleFulfilled)
      .addCase(upsertDisclosureData.rejected, handleRejected)
      .addCase(getMaturityLevels.pending, handlePending)
      .addCase(getMaturityLevels.fulfilled, handleFulfilled)
      .addCase(getMaturityLevels.rejected, handleRejected);
  },
});

export default dgaSustainabilityMattersSlice.reducer;