import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDGASustainabilityMatters,
  getDGADisclosureRequirements,
  upsertDGASustainabilityMatters,
  upsertDisclosureData,
} from "../../../features/slices/DGAContext";
import { MotifProgressLoader, MotifButton } from "@ey-xd/motif-react";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { useTranslation } from "react-i18next";
import CommentBox from "../../../components/commentBox/CommentBox";
import "./UnderstandTheContext.scss";

const DgaUnderstandTheContext = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedDrItemId, setSelectedDrItemId] = useState(null);
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const { t } = useTranslation();
  const [smData, setSmData] = useState([]);
  const [drData, setDrData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [comment, setComment] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [label, setLabel] = useState("");
  const [selectedView, setSelectedView] = useState("SMEsrs16");
  const [drComment, setDrComment] = useState("");
  const [isDrToggled, setIsDrToggled] = useState(false);
  const [drLabel, setDrLabel] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [smResponse, drResponse] = await Promise.all([
          dispatch(getDGASustainabilityMatters({ moduleId, token })),
          dispatch(getDGADisclosureRequirements({ moduleId, token })),
        ]);

        const smData = smResponse.payload.data || [];
        const drData = drResponse.payload.data || [];
        setSmData(smData);
        setDrData(drData);

        if (smData.length > 0) {
          const firstItem = smData[0];
          setSelectedItemId(firstItem.id);
          setComment(firstItem.comment || "");
          setIsToggled(firstItem.materiality || false);
          setLabel(firstItem.label || firstItem.name || "");
        }
        if (drData.length > 0) {
          const firstDrItem = drData[0];
          setSelectedDrItemId(firstDrItem.id);
          setDrComment(firstDrItem.drText || "");
          setIsDrToggled(firstDrItem.materiality || false);
          setDrLabel(firstDrItem.label || firstDrItem.name || "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, moduleId, token]);

  const findItemById = useCallback((data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  }, []);

  const handleEditClick = useCallback(
    (id) => {
      if (selectedView === "SMEsrs16") {
        const selectedItem = findItemById(smData, id);
        if (selectedItem) {
          setSelectedItemId(id);
          setComment(selectedItem.comment || "");
          setIsToggled(selectedItem.materiality || false);
          setLabel(selectedItem.label || selectedItem.name || "");
        }
      } else if (selectedView === "Disclosure") {
        const selectedItem = findItemById(drData, id);
        if (selectedItem) {
          setSelectedDrItemId(id);
          setDrComment(selectedItem.drText || "");
          setIsDrToggled(selectedItem.materiality || false);
          setDrLabel(selectedItem.label || selectedItem.name || "");
        }
      }
    },
    [selectedView, smData, drData, findItemById]
  );

  const handleSMSave = useCallback(
    async (comment, isToggled, selectedItemId, setData) => {
      setIsLoading(true);
      try {
        const newData = {
          moduleSustainabilityMatterId: selectedItemId,
          comment,
          materiality: isToggled,
          moduleId,
        };
        await dispatch(
          upsertDGASustainabilityMatters({
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();

        const response = await dispatch(
          getDGASustainabilityMatters({ moduleId, token })
        );
        const data = response.payload.data || [];
        setData(data);
      } catch (error) {
        console.error("Creation failed");
      }
      setIsLoading(false);
    },
    [dispatch, moduleId, token]
  );

  const handleDRSave = useCallback(
    async (comment, isToggled, selectedItemId, setData) => {
      setIsLoading(true);
      try {
        const newData = {
          moduleId,
          drStaticId: selectedItemId,
          comment,
          materiality: isToggled,
        };
        await dispatch(
          upsertDisclosureData({
            disclosureData: newData,
            token,
          })
        ).unwrap();

        const response = await dispatch(
          getDGADisclosureRequirements({ moduleId, token })
        );
        const data = response.payload.data || [];
        setData(data);
      } catch (error) {
        console.error("Creation failed");
      }
      setIsLoading(false);
    },
    [dispatch, moduleId, token]
  );

  const handleSaveForView = useCallback(
    async (comment, isToggled) => {
      if (selectedView === "SMEsrs16") {
        await handleSMSave(comment, isToggled, selectedItemId, setSmData);
      } else if (selectedView === "Disclosure") {
        await handleDRSave(comment, isToggled, selectedDrItemId, setDrData);
      }
    },
    [selectedView, handleSMSave, handleDRSave, selectedItemId, selectedDrItemId]
  );

  const transformedESRSItems = useMemo(
    () =>
      smData.map((item) => ({
        id: item.id,
        itemId: item.id,
        label: item.label || item.name,
        children: item.children || [],
      })) || [],
    [smData]
  );

  const mapToParentChildren = (items) => {
    const itemMap = {};
    const result = [];

    items.forEach((item) => {
      itemMap[item.id] = { ...item, children: [] };
    });

    items.forEach((item) => {
      if (item.parentId !== null) {
        itemMap[item.parentId].children.push(itemMap[item.id]);
      } else {
        result.push(itemMap[item.id]);
      }
    });

    return result;
  };

  const transformedDRItems = useMemo(
    () => mapToParentChildren(drData) || [],
    [drData]
  );
  const handleSearchInputChange = useCallback((event) => {
    setSearchInput(event);
  }, []);

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (
          item?.label?.toLowerCase().includes(query.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query.toLowerCase())
        )
          return item;
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0)
            return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredESRSItems = filterItems(transformedESRSItems, searchInput);
  const filteredDRItems = filterItems(transformedDRItems, searchInput);

  const handleCancel = useCallback(() => {
    console.log("Cancelled");
  }, []);

  const renderTreeView = (items, handleEditClick, selectedItemId) => (
    <div className="motif-col-1 motif-col-lg-8 sm-file-explorer-container">
      <TreeView
        items={items}
        pageName="DgaContext"
        defaultExpandedItems={[]}
        defaultSelectedItems={selectedItemId ? [selectedItemId] : []}
        onEditClick={handleEditClick}
        onSelect={handleEditClick}
      />
    </div>
  );

  const renderCommentBox = (
    label,
    comment,
    setComment,
    isToggled,
    setIsToggled,
    handleSave
  ) => (
    <div className="motif-col-1 motif-col-lg-4 comment-box-container">
      <CommentBox
        onSave={handleSave}
        onCancel={handleCancel}
        label={label}
        cancelButtonLabel={t("Cancel")}
        saveButtonLabel={t("Save")}
        isMaterial={t("isMaterial")}
        comment={comment}
        setComment={setComment}
        isToggled={isToggled}
        setIsToggled={setIsToggled}
      />
    </div>
  );

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div className="context-selection-container">
          <ContextSelection
            pageName="DgaContext"
            downloadButtonText="Download longlist"
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
      )}
      <div className="dga-assessment-subheader-section2">
        <MotifButton
          className={`button cancel-button next-previous-btn ${
            selectedView === "SMEsrs16" ? "selected" : ""
          }`}
          onClick={() => setSelectedView("SMEsrs16")}
        >
          SM ESRS-16
        </MotifButton>
        <MotifButton
          className={`button cancel-button next-previous-btn ${
            selectedView === "Disclosure" ? "selected" : ""
          }`}
          onClick={() => setSelectedView("Disclosure")}
        >
          Disclosure
        </MotifButton>
      </div>
      <div className="motif-row dga-assessment">
        {selectedView === "SMEsrs16" && (
          <>
            {renderTreeView(filteredESRSItems, handleEditClick, selectedItemId)}
            {renderCommentBox(
              label,
              comment,
              setComment,
              isToggled,
              setIsToggled,
              handleSaveForView
            )}
          </>
        )}
        {selectedView === "Disclosure" && (
          <>
            {renderTreeView(filteredDRItems, handleEditClick, selectedDrItemId)}
            {renderCommentBox(
              drLabel,
              drComment,
              setDrComment,
              isDrToggled,
              setIsDrToggled,
              handleSaveForView
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DgaUnderstandTheContext;
