import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_SH_URL,
  DELETE_SH,
  GET_ALL_SH_URL,
  GET_SH_IE_TYPES_URL,
  GET_SH_TYPES_URL,
  UPDATE_SH_URL,
} from "../../services/Api";

// Constants for action types
const FETCH_STAKEHOLDER_TYPES = "dmaStakeHolder/fetchStakeHolderTypes";
const FETCH_STAKEHOLDER_IE_TYPES = "dmaStakeHolder/fetchStakeHolderIETypes";
const FETCH_STAKEHOLDER = "dmaStakeHolder/fetchStakeHolder";
const CREATE_NEW_STAKEHOLDER = "dmaStakeHolder/createNewStakeHolder";
const UPDATE_STAKEHOLDER = "dmaStakeHolder/updateStakeHolder";
const DELETE_STAKEHOLDER = "dmaStakeHolder/deleteStakeHolder";

// Helper function for API calls
const fetchData = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return rejectWithValue(
      error.response?.data?.message ||
        error.response?.data?.title ||
        error.message ||
        `Error fetching data from ${url}`
    );
  }
};

// Async thunks
export const fetchStakeHolderTypes = createAsyncThunk(
  FETCH_STAKEHOLDER_TYPES,
  async ({ token }, { rejectWithValue }) => {
    return fetchData("get", GET_SH_TYPES_URL, null, token, rejectWithValue);
  }
);

export const fetchStakeHolderIETypes = createAsyncThunk(
  FETCH_STAKEHOLDER_IE_TYPES,
  async ({ token }, { rejectWithValue }) => {
    return fetchData("get", GET_SH_IE_TYPES_URL, null, token, rejectWithValue);
  }
);

export const fetchStakeHolder = createAsyncThunk(
  FETCH_STAKEHOLDER,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData("get", `${GET_ALL_SH_URL}/${moduleId}`, null, token, rejectWithValue);
  }
);

export const createNewStakeHolder = createAsyncThunk(
  CREATE_NEW_STAKEHOLDER,
  async ({ moduleId, StakeHolderData, token }, { rejectWithValue }) => {
    return fetchData("post", `${CREATE_SH_URL}/${moduleId}`, StakeHolderData, token, rejectWithValue);
  }
);

export const updateStakeHolder = createAsyncThunk(
  UPDATE_STAKEHOLDER,
  async ({ StakeHolderId, moduleId, StakeHolderData, token }, { rejectWithValue }) => {
    return fetchData("put", `${UPDATE_SH_URL}/${StakeHolderId}/${moduleId}`, StakeHolderData, token, rejectWithValue);
  }
);

export const deleteStakeHolder = createAsyncThunk(
  DELETE_STAKEHOLDER,
  async ({ StakeHolderId, moduleId, token }, { rejectWithValue }) => {
    return fetchData("delete", `${DELETE_SH}/${StakeHolderId}?moduleId=${moduleId}`, null, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
  updateSuccess: false,
  postSuccess: false,
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
  state.isError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const handleUpdatePending = (state) => {
  state.isLoading = true;
  state.updateSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleUpdateFulfilled = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleUpdateRejected = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const handleCreatePending = (state) => {
  state.isLoading = true;
  state.postSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleCreateFulfilled = (state, action) => {
  state.isLoading = false;
  state.postSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleCreateRejected = (state, action) => {
  state.isLoading = false;
  state.postSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const dmaStakeHolderSlice = createSlice({
  name: "dmaStakeHolder",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchStakeHolderTypes.pending, handlePending)
      .addCase(fetchStakeHolderTypes.fulfilled, handleFulfilled)
      .addCase(fetchStakeHolderTypes.rejected, handleRejected)
      .addCase(fetchStakeHolderIETypes.pending, handlePending)
      .addCase(fetchStakeHolderIETypes.fulfilled, handleFulfilled)
      .addCase(fetchStakeHolderIETypes.rejected, handleRejected)
      .addCase(fetchStakeHolder.pending, handlePending)
      .addCase(fetchStakeHolder.fulfilled, handleFulfilled)
      .addCase(fetchStakeHolder.rejected, handleRejected)
      .addCase(createNewStakeHolder.pending, handleCreatePending)
      .addCase(createNewStakeHolder.fulfilled, handleCreateFulfilled)
      .addCase(createNewStakeHolder.rejected, handleCreateRejected)
      .addCase(updateStakeHolder.pending, handleUpdatePending)
      .addCase(updateStakeHolder.fulfilled, handleUpdateFulfilled)
      .addCase(updateStakeHolder.rejected, handleUpdateRejected)
      .addCase(deleteStakeHolder.pending, handlePending)
      .addCase(deleteStakeHolder.fulfilled, handleFulfilled)
      .addCase(deleteStakeHolder.rejected, handleRejected);
  },
});

export default dmaStakeHolderSlice.reducer;