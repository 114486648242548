import React, { useState, useEffect } from "react";
import {
  MotifButton,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifMessage,
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifLabel,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./MappingForm.scss";

const MappingForm = ({
  responsibleLabel,
  accountableLabel,
  consultedLabel,
  informedLabel,
  othersLabel,
  selectResponsilbleLabel,
  selectAccountableLabel,
  selectConsultedLabel,
  selectInformedLabel,
  selectOthersLabel,
  RACIMapping,
  saveButtonLabel,
  cancelButtonLabel,
  onCancel,
  onSave,
  raciDropdownData,
  initialData,
  pagenName,
}) => {
  const [responsible, setResponsible] = useState("");
  const [accountable, setAccountable] = useState("");
  const [consulted, setConsulted] = useState("");
  const [informed, setInformed] = useState("");
  const [others, setOthers] = useState("");

  // Effect to update form fields when initialData changes
  useEffect(() => {
    if (initialData) {
      setResponsible(initialData.responsible || "");
      setAccountable(initialData.accountable || "");
      setConsulted(initialData.consulted || "");
      setInformed(initialData.informed || "");
      setOthers(initialData.others || "");
    }
  }, [initialData]);

  const handleSave = () => {
    const formData = { responsible, accountable, consulted, informed, others };
    onSave(formData);
  };

  return (
    <MotifCard className="mapping-card">
      {pagenName === "IROAssessment" && (
        <MotifCardHeader className="mapping-header" variant="alt">
          {RACIMapping}
        </MotifCardHeader>
      )}
      <MotifCardBody>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{responsibleLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel>{selectResponsilbleLabel}</MotifLabel>
              <MotifSelect
                value={responsible}
                onChange={(e) => setResponsible(e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="form-group full-width">
            <MotifMessage>{accountableLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel>{selectAccountableLabel}</MotifLabel>
              <MotifSelect
                value={accountable}
                onChange={(e) => setAccountable(e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{consultedLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel>{selectConsultedLabel}</MotifLabel>
              <MotifSelect value={consulted} onChange={(e) => setConsulted(e)}>
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="form-group full-width">
            <MotifMessage>{informedLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel>{selectInformedLabel}</MotifLabel>
              <MotifSelect value={informed} onChange={(e) => setInformed(e)}>
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{othersLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel>{selectOthersLabel}</MotifLabel>
              <MotifSelect value={others} onChange={(e) => setOthers(e)}>
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        {pagenName === "IROAssessment" && (
          <div className="form-footer">
            <MotifButton onClick={onCancel} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton onClick={handleSave} className="button save-button">
              {saveButtonLabel}
            </MotifButton>
          </div>
        )}
      </MotifCardBody>
    </MotifCard>
  );
};

MappingForm.propTypes = {
  responsibleLabel: PropTypes.string.isRequired,
  accountableLabel: PropTypes.string.isRequired,
  consultedLabel: PropTypes.string.isRequired,
  informedLabel: PropTypes.string.isRequired,
  othersLabel: PropTypes.string.isRequired,
  selectResponsilbleLabel: PropTypes.string.isRequired,
  selectAccountableLabel: PropTypes.string.isRequired,
  selectConsultedLabel: PropTypes.string.isRequired,
  selectInformedLabel: PropTypes.string.isRequired,
  selectOthersLabel: PropTypes.string.isRequired,
  RACIMapping: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  raciDropdownData: PropTypes.array.isRequired,
  initialData: PropTypes.object,
};

export default MappingForm;
