import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import "./Likelihood.scss";
import { useTranslation } from "react-i18next";
import LikelihoodModal from "../../../../components/modals/dmaModals/LikelihoodModal";
import {
  fetchLikelihood,
  createLikelihood,
  updateLikelihood,
  deleteLikelihood,
} from "../../../../features/slices/Likelihood";
import { useDispatch, useSelector } from "react-redux";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ErrorModal from "../../../../components/modals/error/errorModal";

const Likelihood = () => {
  const [state, setState] = useState({
    isLikelihoodModalOpen: false,
    modalLikelihoodMode: "add",
    modalLikelihoodTitle: "",
    selectedLikelihoodRow: null,
    searchLikelihoodQuery: "",
    isDeleteModalOpen: false,
    likelihoodToDelete: null,
    updateLikelihoodTrigger: 0,
    isLoading: false,
    likelihoodData: [],
  });

  const navigate = useNavigate();
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const { t } = useTranslation();

  const {
    data: responseData,
    isError,
    errorMessage,
  } = useSelector((state) => state.likelihood);

  const fetchData = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const likelihoodResponse = await dispatch(
        fetchLikelihood({ moduleId, token })
      ).unwrap();

      if (Array.isArray(likelihoodResponse.data)) {
        setState((prevState) => ({
          ...prevState,
          likelihoodData: likelihoodResponse.data,
        }));
      } else {
        setState((prevState) => ({ ...prevState, likelihoodData: [] }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.updateLikelihoodTrigger]);

  useEffect(() => {
    if (Array.isArray(responseData?.data)) {
      setState((prevState) => ({
        ...prevState,
        likelihoodData: responseData.data,
      }));
    }
  }, [responseData]);

  const handleEdit = (row) => {
    setState((prevState) => ({
      ...prevState,
      selectedLikelihoodRow: row,
      modalLikelihoodMode: "edit",
      modalLikelihoodTitle: "Edit likelihood",
      isLikelihoodModalOpen: true,
    }));
  };
  const handleDelete = async () => {
    const { likelihoodToDelete, likelihoodData } = state;
    if (!likelihoodToDelete) return;
    if (likelihoodToDelete.rating === 0 || likelihoodToDelete.rating === 1) {
      setState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
        isErrorModalOpen: true, // Show the error modal
        errorMessage: "Rows Containing rating 0 or 1 cannot be deleted", // Set the error message
      }));
      return;
    }

    const remainingLikelihoods = likelihoodData.filter(
      (likelihood) => likelihood.id !== likelihoodToDelete.id
    );

    if (remainingLikelihoods.length < 3) {
      alert("Minimum 3 Rows should be present");
      return;
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    dispatch(
      deleteLikelihood({ likelihoodId: likelihoodToDelete.id, moduleId, token })
    )
      .then((response) => {
        if (response && !response.error) {
          setState((prevState) => ({
            ...prevState,
            likelihoodData: prevState.likelihoodData.filter(
              (likelihood) => likelihood.id !== likelihoodToDelete.id
            ),
            updateLikelihoodTrigger: prevState.updateLikelihoodTrigger + 1,
            isDeleteModalOpen: false,
          }));
          return dispatch(fetchLikelihood({ moduleId, token }));
        } else {
          console.error("Error deleting likelihood:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error deleting likelihood:", error);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const openDeleteModal = (row) => {
    setState((prevState) => ({
      ...prevState,
      likelihoodToDelete: row,
      isDeleteModalOpen: true,
    }));
  };
  const handleLikelihoodValueClick = async (likelihoodData) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const { modalLikelihoodMode, selectedLikelihoodRow } = state;
      let maxRating = Math.max(
        ...(Array.isArray(likelihoodData) ? likelihoodData : [])
          .map((item) => item.rating)
          .filter((r) => r !== 100),
        0
      );
      const mappedLikelihoodData = {
        rating: likelihoodData.likelihood
          ? Math.min(Math.max(parseFloat(likelihoodData.likelihood), 0), 100)
          : maxRating,
        likelihoodLabel: likelihoodData.name || "new",
        likelihoodDefinition: likelihoodData.definition || "",
      };

      let response;
      if (modalLikelihoodMode === "add") {
        response = await dispatch(
          createLikelihood({
            moduleId,
            likelihoodData: mappedLikelihoodData,
            token,
          })
        ).unwrap();
      } else if (modalLikelihoodMode === "edit" && selectedLikelihoodRow) {
        response = await dispatch(
          updateLikelihood({
            likelihoodId: selectedLikelihoodRow.id,
            likelihoodData: mappedLikelihoodData,
            moduleId,
            token,
          })
        ).unwrap();
      }

      if (response) {
        setState((prevState) => ({
          ...prevState,
          likelihoodData:
            modalLikelihoodMode === "add"
              ? [...prevState.likelihoodData, response]
              : prevState.likelihoodData.map((likelihood) =>
                  likelihood.id === response.id ? response : likelihood
                ),
          updateLikelihoodTrigger: prevState.updateLikelihoodTrigger + 1,
          isLikelihoodModalOpen: false,
        }));
        dispatch(fetchLikelihood({ moduleId, token }));
      } else {
        console.error(
          `Error ${
            modalLikelihoodMode === "add" ? "creating" : "updating"
          } likelihood:`,
          response.error
        );
      }
    } catch (error) {
      console.error("Error creating/updating likelihood:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderLikelihoodTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "3.85%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setState((prevState) => ({
      ...prevState,
      modalLikelihoodMode: mode,
      selectedLikelihoodRow: null,
      modalLikelihoodTitle:
        mode === "add" ? "New likelihood" : "Edit likelihood",
      isLikelihoodModalOpen: true,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, isLikelihoodModalOpen: false }));
  };

  const handleSearch = (event) => {
    setState((prevState) => ({
      ...prevState,
      searchLikelihoodQuery: String(event),
    }));
  };

  const handleError = () => {
    navigate("/");
  };
  const closeErrorModal = () => {
    setState((prevState) => ({
      ...prevState,
      isErrorModalOpen: false,
      errorMessage: "",
    }));
  };
  if (isError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage}
        handleButtonClick={handleError}
        deleteButtonLabel={t("Reload")}
      />
    );
  }

  const getLikelihoodFilteredData = () => {
    return Array.isArray(state.likelihoodData)
      ? state.likelihoodData.filter((item) =>
          item.likelihoodLabel
            ?.toLowerCase()
            .includes(state.searchLikelihoodQuery.toLowerCase())
        )
      : [];
  };

  const columnsLikelihood = [
    { colName: "rating", label: t("Likelihood"), showSorting: false },
    { colName: "likelihoodLabel", label: t("Name"), showSorting: false },
    {
      colName: "likelihoodDefinition",
      label: t("Definition"),
      showSorting: false,
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  return (
    <div>
      {state.isLoading && (
        <MotifProgressLoader
          className="loader"
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        pageName="Likelihood"
        onChangeSearchInput={handleSearch}
      />
      <div className="gl-card-table-switch">
        <ClientTable
          columns={columnsLikelihood}
          data={getLikelihoodFilteredData()}
          itemsPerPage={5}
          renderTableCell={(colName, value, row, index) => {
            const cellValue = colName
              .split(".")
              .reduce((acc, part) => acc?.[part], row);
            return renderLikelihoodTableCell(colName, cellValue, row, index);
          }}
          generateRowKey={generateRowKey}
        />
      </div>
      {state.isLikelihoodModalOpen && (
        <LikelihoodModal
          onClose={handleCloseModal}
          setName={state.modalLikelihoodTitle}
          handleStatusChange={() => {}}
          modalMode={state.modalLikelihoodMode}
          NameLabel={t("Name")}
          LikelihoodLabel={t("Likelihood")}
          DefinitionLabel={t("Definition")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("Save")}
          updateButtonLabel={t("Update")}
          selectedRow={state.selectedLikelihoodRow}
          handleLikelihoodValueClick={handleLikelihoodValueClick}
        />
      )}
      {state.isDeleteModalOpen && (
        <DeleteModal
          isOpen={state.isDeleteModalOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
          handleDeleteClick={handleDelete}
        />
      )}
      {state.isErrorModalOpen && (
        <ErrorModal
          setName="Error"
          handleButtonClick={closeErrorModal}
          labelText={state.errorMessage}
          deleteButtonLabel={t("Close")}
        />
      )}
    </div>
  );
};

export default Likelihood;
