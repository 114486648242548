import React, { useEffect, useMemo, useCallback, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLeft, EditSvg, Dot } from "../../constants/icons/Icons";
import Header from "../../components/headers/Header";
import Card from "../../components/card/Card";
import "./InstanceMaintenance.scss";
import { useNavigate } from "react-router-dom";
import ChangeStatusModal from "./ChangeStatusModal";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import maintenanceLanguage from "../../constants/languages/en/translations.json";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import InstanceHome from "../instanceHome/InstanceHome";
import ErrorModal from "../../components/modals/error/errorModal";
import {
  getAllStatus,
  getMaintainenceStatus,
} from "../../features/slices/InstanceMaintenance";
import { useTranslation } from "react-i18next";

const initialState = {
  isOpen: false,
  allStatusData: [],
  status: "",
  instanceName: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_OPEN":
      return { ...state, isOpen: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_INSTANCE_NAME":
      return { ...state, instanceName: action.payload };
    case "SET_ALL_STATUS_DATA":
      return { ...state, allStatusData: action.payload };
    default:
      return state;
  }
};

const InstanceMaintenance = () => {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    reduxDispatch(getAllStatus({ token }));
  }, [reduxDispatch, token]);

  const {
    data: responseData,
    isLoading,
    isError,
    errorMessage,
  } = useSelector(
    (state) => state.allStatus || { data: null, isLoading: false }
  );

  const labelMappingApiResponse = useMemo(
    () => ({
      totalProjects: maintenanceLanguage.totalProjects,
      activeProjects: maintenanceLanguage.activeProjects,
      retiredProjects: maintenanceLanguage.retiredProjects,
      totalUsers: maintenanceLanguage.totalUsers,
      totalSessions: maintenanceLanguage.totalSessions,
      totalDMAModules: maintenanceLanguage.totalDMAModules,
      totalDDModules: maintenanceLanguage.totalDDModules,
      totalHLModules: maintenanceLanguage.totalHLModules,
    }),
    []
  );

  useEffect(() => {
    if (responseData) {
      const instanceData = responseData.data;
      dispatchLocal({ type: "SET_STATUS", payload: instanceData.status });
      dispatchLocal({
        type: "SET_INSTANCE_NAME",
        payload: instanceData.instanceName,
      });

      const transformedData = Object.entries(instanceData)
        .filter(([key]) => key !== "instanceName" && key !== "status")
        .map(([key, value]) => ({
          label: labelMappingApiResponse[key] || key,
          value,
        }));

      dispatchLocal({ type: "SET_ALL_STATUS_DATA", payload: transformedData });
    }
  }, [responseData, labelMappingApiResponse]);

  const handleNavLeft = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleModalOpen = useCallback(() => {
    dispatchLocal({ type: "SET_IS_OPEN", payload: true });
  }, []);

  const handleClose = useCallback(() => {
    dispatchLocal({ type: "SET_IS_OPEN", payload: false });
  }, []);

  const handleStatusUpdate = useCallback(
    (selectedStatus, message) => {
      const isActive = selectedStatus === maintenanceLanguage.StatusActiveEN;
      const updatePayload = {
        isActive,
        messageWhenInactive: !isActive ? message : "",
        lastModifiedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      };

      reduxDispatch(getMaintainenceStatus(updatePayload))
        .unwrap()
        .then(() => {
          handleClose();
          reduxDispatch(getAllStatus({ token }));
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
    },
    [reduxDispatch, handleClose, token]
  );

  const handleError = useCallback(() => {
    navigate("/");
  }, [navigate]);

  if (isError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage}
        handleButtonClick={handleError}
        deleteButtonLabel={t("Reload")}
      />
    );
  }

  return (
    <div>
      <Header />
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {state.isOpen && (
        <ChangeStatusModal
          onClose={handleClose}
          setInstanceName={state.instanceName}
          handleStatusUpdate={handleStatusUpdate}
          currentStatus={state.status}
        />
      )}
      <div className="maintenance-container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: "Settings",
              link: "/maintenance",
            },
          ]}
        />
        <div className="maintenance-header-container">
          <div className="maintenance-section1">
            <div
              data-testid="navButton"
              className="nav-left-btn"
              onClick={handleNavLeft}
            >
              <NavLeft />
            </div>
            <p
              data-testid="maintenance-subheader"
              className="motif-h5-default-bold"
            >
              {t("Maintenance")}
            </p>
          </div>
          <div className="maintenance-section2">
            <MotifButton
              className="active-btn"
              size="small"
              type="submit"
              variant="primary-alt"
            >
              <Dot />
              &nbsp; {state.status}
            </MotifButton>
            <MotifButton
              className="subheader-button-add"
              size="small"
              type="submit"
              variant="primary-alt"
              onClick={handleModalOpen}
            >
              {t("ChangeStatus")}
              &nbsp;
              <EditSvg />
            </MotifButton>
          </div>
        </div>
        <div className="cards-container">
          {state.allStatusData.map(({ label, value }) => (
            <Card key={label} label={label} value={value} />
          ))}
        </div>
      </div>
      <InstanceHome showHeader={false} showButton={false} marginTop={"2%"} />
    </div>
  );
};

export default InstanceMaintenance;
