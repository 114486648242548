import React, {
  useEffect,
  useReducer,
  useRef,
  useMemo,
  useCallback,
  Suspense,
  useState,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./UserManagement.scss";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import AddUser from "../../components/modals/addUser/AddUser";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import language from "../../constants/languages/en/translations.json";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  createNewUser,
  updateUser,
  deleteUser,
  fetchProjectUserRoles,
} from "../../features/slices/ProjectScreens";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);

const initialState = {
  isModalOpen: false,
  isUpdateModalOpen: false,
  isDeleteModalOpen: false,
  modalType: "",
  searchQuery: "",
  updateTrigger: 0,
  userName: "",
  role: "",
  email: "",
  emailOptions: [],
  selectedUserId: null,
  isApprover: false,
  selectedId: null,
  transformedData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_MODAL_OPEN":
      return { ...state, isModalOpen: action.payload };
    case "SET_IS_UPDATE_MODAL_OPEN":
      return { ...state, isUpdateModalOpen: action.payload };
    case "SET_IS_DELETE_MODAL_OPEN":
      return { ...state, isDeleteModalOpen: action.payload };
    case "SET_MODAL_TYPE":
      return { ...state, modalType: action.payload };
    case "SET_SEARCH_QUERY":
      return { ...state, searchQuery: action.payload };
    case "INCREMENT_UPDATE_TRIGGER":
      return { ...state, updateTrigger: state.updateTrigger + 1 };
    case "SET_USER_NAME":
      return { ...state, userName: action.payload };
    case "SET_ROLE":
      return { ...state, role: action.payload };
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_SELECTED_USER_ID":
      return { ...state, selectedUserId: action.payload };
    case "IS_APPROVER":
      return { ...state, isApprover: action.payload };
    case "SET_SELECTED_ID":
      return { ...state, selectedId: action.payload };
    case "SET_TRANSFORMED_DATA":
      return { ...state, transformedData: action.payload };
    default:
      return state;
  }
};

const UserManagement = () => {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const isAdmin = true;
  const location = useLocation();
  const navigate = useNavigate();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token = useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const userIDsRef = useRef([]);
  const projectName = location.state?.projectName;
  const ProjectAdmin = "Project admin";
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageTemp, setErrorMessageTemp] = useState(null);
  const {
    data: responseData = { data: [] },
    isLoading,
    isError,
    errorMessage,
  } = useSelector((state) => state.projectDetails);

  const fetchUserRoles = useCallback(async () => {
    dispatch(fetchProjectUserRoles({ token }))
      .unwrap()
      .then((response) => {
        if (response.success) {
          setUserRoles(response.data);
        }
      })
      .catch((error) => {
        console.error("fetching of roles failed:", error);
      });
  }, [dispatch, token]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await dispatch(fetchUsers({ projectId, token })).unwrap();
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUserData();
    fetchUserRoles();
  }, [dispatch, projectId, state.updateTrigger, token, fetchUserRoles]);

  useEffect(() => {
    if (Array.isArray(responseData?.data)) {
      userIDsRef.current = responseData.data.map((item) => item.id);
      const data = responseData.data.map((item) => ({
        userId: item.user?.id,
        Name: item.user?.name,
        Email: item.user?.email,
        Role: item.role?.name,
      }));
      dispatchLocal({ type: "SET_TRANSFORMED_DATA", payload: data });
    }
  }, [responseData]);

  const handleOpenModal = useCallback(async () => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: language.UserManagementPage,
    });
  }, []);
  const handleCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: false });
  }, []);

  const handleSearch = useCallback((event) => {
    dispatchLocal({ type: "SET_SEARCH_QUERY", payload: event.target.value });
  }, []);
  const handleUpdateOpenModal = useCallback(
    async (userId, role, name, email) => {
      const currentUser = responseData?.data?.find(
        (user) => user.user.id === userId
      );

      if (!currentUser) {
        console.error("User not found or insufficient permissions.");
        return;
      }

      const isProjectAdmin =
        currentUser.role.name.toLowerCase() === ProjectAdmin.toLowerCase();
      const admins = responseData?.data?.filter(
        (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
      );

      if (isProjectAdmin && admins.length <= 1) {
        console.error("Cannot edit the only remaining Project Admin.");
        return;
      }

      // Validating email domain when not assigning "Project Admin" role
      if (
        !isProjectAdmin &&
        !(
          email.trim().toLowerCase().endsWith("@ey.com") ||
          email.trim().toLowerCase().endsWith(".ey.com")
        )
      ) {
        console.error("Invalid email domain.");
        return;
      }

      dispatchLocal({ type: "SET_IS_UPDATE_MODAL_OPEN", payload: true });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: language.UpdateUserManagementPage,
      });
      dispatchLocal({ type: "SET_SELECTED_USER_ID", payload: userId });
      dispatchLocal({ type: "IS_APPROVER", payload: currentUser.isApprover });
      dispatchLocal({ type: "SET_SELECTED_ID", payload: currentUser.id });
      dispatchLocal({ type: "SET_ROLE", payload: currentUser.role.id });
      dispatchLocal({ type: "SET_EMAIL", payload: email });
      dispatchLocal({ type: "SET_USER_NAME", payload: name });
    },
    [responseData, ProjectAdmin]
  );

  const handleUpdateCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_UPDATE_MODAL_OPEN", payload: false });
  }, []);

  const handleDeleteOpenModal = useCallback(
    (userId, role) => {
      const admins = responseData?.data?.filter(
        (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
      );

      if (
        role.toLocaleLowerCase() === ProjectAdmin.toLocaleLowerCase() &&
        admins?.length <= 1
      ) {
        setErrorMessageTemp("There must be at least one Project Admin.");
        setShowErrorModal(true);
      }
      dispatchLocal({ type: "SET_IS_DELETE_MODAL_OPEN", payload: true });
      dispatchLocal({ type: "SET_SELECTED_USER_ID", payload: userId });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: language.ConfirmationPopup,
      });
    },
    [responseData, ProjectAdmin]
  );

  const handleDeleteCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_DELETE_MODAL_OPEN", payload: false });
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    const admins = responseData?.data?.filter(
      (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
    );

    const currentUser = responseData?.data?.find(
      (user) => user.user.id === state.selectedUserId
    );

    if (
      currentUser &&
      currentUser.role.name.toLowerCase() === ProjectAdmin.toLowerCase() &&
      admins?.length <= 1
    ) {
      return;
    }

    await dispatch(
      deleteUser({ projectId, userId: state.selectedUserId, token })
    )
      .unwrap()
      .then(() => {
        dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
        handleDeleteCloseModal();
      })
      .catch((error) => {
        console.error("Deletion failed:", error);
      });
  }, [
    dispatch,
    projectId,
    state.selectedUserId,
    token,
    handleDeleteCloseModal,
    responseData,
    ProjectAdmin,
  ]);

  const handleButtonClick = useCallback(
    (row, buttonName) => {
      switch (buttonName) {
        case "Edit":
          handleUpdateOpenModal(row.userId, row.Role, row.Name, row.Email);
          break;
        case "Delete":
          handleDeleteOpenModal(row.userId, row.Role);
          break;
        default:
      }
    },
    [handleUpdateOpenModal, handleDeleteOpenModal]
  );

  const columnsToDisplay = useMemo(
    () => [
      { colName: "Name", label: "Name", showSorting: true },
      { colName: "Role", label: "Role", showSorting: true },
      { colName: "Email", label: "Email", showSorting: true },
      { colName: "Action", label: "Action", showSorting: false },
    ],
    []
  );

  const createNewUserInAdminUser = useCallback(
    async (userData) => {
      if (
        userData.aadId &&
        userData.name &&
        userData.email &&
        userData.projectRoleId
      ) {
        try {
          const response = await dispatch(
            createNewUser({ projectId, userData, token })
          ).unwrap();
          if (response.success) {
            dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
            handleCloseModal();
          } else {
            console.error("Creation failed:", response.error);
          }
        } catch (error) {
          console.error("Creation failed:", error);
        }
      } else {
        alert("Please fill all the required fields");
      }
    },
    [dispatch, projectId, token, handleCloseModal, dispatchLocal]
  );

  const updateUserInAdminUser = useCallback(
    async (userData) => {
      if (userData.projectRoleId) {
        userData.userId = state.selectedUserId;
        userData.id = state.selectedId;
        userData.projectId = projectId;
        await dispatch(updateUser({ userData, token }))
          .unwrap()
          .then((response) => {
            if (response) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleUpdateCloseModal();
            }
          })
          .catch((error) => {
            console.error("Update failed:", error);
          });
      } else {
        alert("Please fill all the fields");
      }
    },
    [
      dispatch,
      projectId,
      token,
      state.selectedUserId,
      handleUpdateCloseModal,
      state.selectedId,
    ]
  );

  const generateRowKey = useCallback((row) => row.userId, []);

  const getCellStyle = useCallback(() => ({ padding: "15px" }), []);

  const renderTableCell = useCallback(
    (column, value, row, index) => {
      const cellStyle = getCellStyle();
      if (column === "Action") {
        return (
          <TableCell key={column} style={cellStyle}>
            <Box display="flex" justifyContent="flex-end" paddingRight="12%">
              <IconButton onClick={() => handleButtonClick(row, "Edit")}>
                <Edit />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => handleButtonClick(row, "Delete")}
              >
                <Delete />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      return (
        <TableCell key={column} style={cellStyle}>
          {value}
        </TableCell>
      );
    },
    [getCellStyle, handleButtonClick]
  );

  const filteredData = useMemo(() => {
    const searchQueryLower = state.searchQuery.toLowerCase();
    return state.transformedData.filter((row) => {
      const nameValue = String(row.Name).toLowerCase();
      const emailValue = String(row.Email).toLowerCase();
      const roleValue = String(row.Role).toLowerCase();
      return (
        nameValue.includes(searchQueryLower) ||
        emailValue.includes(searchQueryLower) ||
        roleValue.includes(searchQueryLower)
      );
    });
  }, [state.transformedData, state.searchQuery]);

  const handleError = () => {
    setShowErrorModal(false);
    handleDeleteCloseModal();
    navigate(`/project-home/${projectId}`, { state: { token } });
  };

  if (isError || showErrorModal) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage || errorMessageTemp}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Ok")}
        />
      </div>
    );
  }

  return (
    <div className="all-user-management-container">
      {isLoading && (
        <MotifProgressLoader
          show
          className="loader"
          variant="default"
          data-testid="loading-spinner"
        />
      )}
      <Header />
      <div className="user-management-org-container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: projectName,
              link: `/project-home/${projectId}`,
              token: token,
            },
            {
              label: "User management",
              link: `/user-management/${projectId}`,
              token: token,
            },
          ]}
        />
        <div className="user-management-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={language.ClientOrgPage}
            title={t("UserManagementTitle")}
            onChangeSearchInput={handleSearch}
            handleOpenModal={handleOpenModal}
            AddNewEN={language.CreateNewEN}
            ClientOrgAccessTitleEN={t("ClientOrgPage")}
            CreateNewEN={t("CreateNew")}
            disableButton={isLoading}
          />
          {state.modalType === language.UserManagementPage && (
            <AddUser
              isOpen={state.isModalOpen}
              onClose={handleCloseModal}
              page={language.UserManagementPage}
              TitleUserManagementEN={t("TitleUserManagement")}
              UserNameEN={t("UserName")}
              RoleEN={t("Role")}
              SelectRoleEN={t("SelectRole")}
              UserEmailEN={t("Email")}
              EmailContentEN={t("EmailContent")}
              CancelButtonEN={t("Cancel")}
              DoneButtonEN={t("DoneButton")}
              UserManagementPageEN={t("UserManagementPage")}
              callApi={createNewUserInAdminUser}
              userRoles={userRoles}
              PreparerLabel={t("Preparer")}
              ApproverLabel={t("Approver")}
            />
          )}
          {state.modalType === language.UpdateUserManagementPage && (
            <AddUser
              isOpen={state.isUpdateModalOpen}
              onClose={handleUpdateCloseModal}
              page={language.UpdateUserManagementPage}
              TitleUserManagementEN={t("TitleUpdateUserManagement")}
              UserNameEN={t("UserName")}
              UserNameContentEN={t("UserNameContent")}
              RoleEN={t("Role")}
              SelectRoleEN={t("SelectRole")}
              UserEmailEN={t("Email")}
              EmailContentEN={t("EmailContent")}
              CancelButtonEN={t("Cancel")}
              UpdateButtonEN={t("Update")}
              TitleUpdateUserManagementEN={t("TitleUpdateUserManagement")}
              SelectRole1EN={t("SelectRole1")}
              SelectRole2EN={t("SelectRole2")}
              UpdateUserManagementPageEN={t("UpdateUserManagementPage")}
              selectedUsername={state.userName}
              selectedUserId={state.selectedUserId}
              selectedRole={state.role}
              selectedEmail={state.email}
              approverValue={state.isApprover}
              callApi={updateUserInAdminUser}
              userRoles={userRoles}
              PreparerLabel={t("Preparer")}
              ApproverLabel={t("Approver")}
            />
          )}

          {state.modalType === language.ConfirmationPopup && (
            <AddUser
              isOpen={state.isDeleteModalOpen}
              onClose={handleDeleteCloseModal}
              page={language.ConfirmationPopup}
              ConfirmationPopup={t("ConfirmationPopup")}
              ConfirmationTitle={t("DeleteLabel")}
              ConfirmationEN={t("DeleteLabel")}
              ConfirmationDeleteMsgEN={t("ConfirmationDeleteMsg")}
              CancelButtonEN={t("Cancel")}
              callApi={handleDeleteConfirm}
            />
          )}
        </div>
        <div>
          <Suspense
            fallback={
              <MotifProgressLoader
                data-testid="loading-spinner"
                show
                variant="default"
              />
            }
          >
            <ClientTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              section={t("InstanceHomeProjects")}
              generateRowKey={generateRowKey}
              getCellStyle={getCellStyle}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
              page={language.UpdateUserManagementPage}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
