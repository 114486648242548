import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Stake,
  Beat,
  Iro,
  IroLite,
  ReportingLite,
  Reporting,
  BeatLite,
  StakeLite,
} from "../../constants/icons/Icons";
import "./DMA.scss";
import DMAOverview from "./dmaOverview/DMAOverview";
import DmaContext from "./dmaContext/DmaContext";
import DmaProcessSpecification from "./dmaProcessSpecification/DmaProcessSpecification";
import DMAReporting from "./dmaReporting/DMAReporting";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import DMAIroOverview from "./dmaIROAssessment/DMAIroOverview";
import DMAIroAssessment from "./dmaIROAssessment/DMAIroAssessment";

const DMA = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("sources");
  const [showAssessment, setShowAssessment] = useState(false);
  const [assessmentIroId, setAssessmentIroId] = useState(0);
  const [iroData, setIroData] = useState([]);

  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const switchToUnderstandTheContext = (tab) => {
    setActiveTab(tab);
    handleStepChange(1);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  const stepItems = [
    { label: t("All projects"), link: "/", key: "all-projects", token },
    {
      label: projectName,
      link: `/project-home/${projectId}`,
      key: `project-home-${projectId}`,
      token,
    },
    {
      label: project?.name,
      link: `/project-home/${projectId}`,
      key: `project-name-${projectId}`,
      token,
    },
    { label: t("DMA"), link: "#", key: "dma" },
  ];

  return (
    <div className="dma-page">
      <Header />
      <Stepper
        activeIndex={activeIndex}
        onChange={handleStepChange}
        items={stepItems}
      >
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("Overview")}>
          <DMAOverview onTabChange={switchToUnderstandTheContext} />
        </div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={t("UnderstandTheContext")}
        >
          <DmaContext activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div
          icon={getIcon(2, <Beat />, <BeatLite />)}
          text={t("ProcessSpecification")}
        >
          <DmaProcessSpecification />
        </div>
        <div
          icon={getIcon(3, <Iro />, <IroLite />)}
          text={t("IROIdentificationAndAssessment")}
        >
          {showAssessment ? (
            <DMAIroAssessment
              setShowAssessment={setShowAssessment}
              setAssessmentIroId={setAssessmentIroId}
              assessmentIroId={assessmentIroId}
              getIroData={iroData}
            />
          ) : (
            <DMAIroOverview
              setShowAssessment={setShowAssessment}
              setAssessmentIroId={setAssessmentIroId}
              setIroData={setIroData}
            />
          )}
        </div>
        <div
          icon={getIcon(4, <Reporting />, <ReportingLite />)}
          text={t("ReportingImplication")}
        >
          <DMAReporting />
        </div>
      </Stepper>
    </div>
  );
};

export default DMA;
