import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import StakeHolder from "../../../../components/modals/dmaModals/StakeHolder";
import {
  fetchStakeHolder,
  fetchStakeHolderIETypes,
  fetchStakeHolderTypes,
  createNewStakeHolder,
  updateStakeHolder,
  deleteStakeHolder,
} from "../../../../features/slices/DMAStakeHolder";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TopBar from "../Topbar";
import Pagination from "../../../../components/paginationForCards/Pagination";
import { MotifProgressLoader, MotifTruncate } from "@ey-xd/motif-react";
import "./Stakeholders.scss";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
const Stakeholders = () => {
  const [isShTableView, setIsShTableView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [modalTitle, setModalTitle] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchShQuery, setSearchShQuery] = useState("");
  const [stakeData, setStakeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [typeOptions, setTypeOptions] = useState([]);
  const [ieOptions, setIeOptions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const itemsPerPage = 10;
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage } = useSelector(
    (state) => state.dmaStakeHolder
  );
  const navigate = useNavigate();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stakeholderResponse, typesResponse, ieTypesResponse] =
          await Promise.all([
            dispatch(fetchStakeHolder({ moduleId, token })).unwrap(),
            dispatch(fetchStakeHolderTypes({ token })).unwrap(),
            dispatch(fetchStakeHolderIETypes({ token })).unwrap(),
          ]);

        if (typesResponse.success) {
          setTypeOptions(
            typesResponse.data.map((type) => ({
              value: type.id,
              label: type.name,
            }))
          );
        }

        if (ieTypesResponse.success) {
          setIeOptions(
            ieTypesResponse.data.map((ieType) => ({
              value: ieType.id,
              label: ieType.name,
            }))
          );
        }

        if (Array.isArray(stakeholderResponse.data)) {
          const flattenedData = stakeholderResponse.data.map((item) => ({
            ...item,
            typeName: item.stakeHolderType?.name || "Unknown",
            ie: item.stakeHolderIntExtType?.name || "Unknown",
          }));
          setStakeData(flattenedData);
        } else {
          setStakeData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, moduleId, updateTrigger, token]);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setModalMode("edit");
    setModalTitle(t("EditStakeHolder"));
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteStakeHolder({ StakeHolderId: selectedRow.id, moduleId, token })
    )
      .unwrap()
      .then(() => {
        handleCloseModal();
        handleDeleteCloseModal();
        setUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        console.error("deletion failed:", error);
      });
  };

  const handleDeleteOpenModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const renderShTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "4.85%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "description") {
      return (
        <TableCell key={index}>
          <MotifTruncate>{value}</MotifTruncate>
        </TableCell>
      );
    }
    return (
      <TableCell key={index}>
        <MotifTruncate>{value}</MotifTruncate>
      </TableCell>
    );
  };

  const createNewStakeHolderApi = async (StakeHolderData) => {
    if (StakeHolderData) {
      dispatch(createNewStakeHolder({ moduleId, StakeHolderData, token }))
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("Creation failed:", error);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateStakeHolderApi = async (StakeHolderData) => {
    if (StakeHolderData) {
      dispatch(
        updateStakeHolder({
          StakeHolderId: selectedRow.id,
          StakeHolderData,
          moduleId,
          token,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("Update failed:", error);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalMode(mode);
    setSelectedRow(null);
    if (mode === "add") {
      setModalTitle(t("NewStakeHolder"));
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchShQuery(String(event));
  };

  const getGlFilteredData = () => {
    return Array.isArray(stakeData)
      ? stakeData.filter((item) =>
          item.name?.toLowerCase().includes(searchShQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getGlFilteredData().slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const columns = [
    { colName: "name", label: "Name", showSorting: true },
    { colName: "ie", label: "I/E", showSorting: true },
    { colName: "influence", label: "Influence", showSorting: true },
    { colName: "power", label: "Power", showSorting: true },
    { colName: "typeName", label: "Type", showSorting: true },
    { colName: "description", label: "Description", showSorting: true },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getGlFilteredData().length / itemsPerPage);

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isShTableView}
        totalResults={stakeData.length}
        filteredResults={getGlFilteredData().length}
        onTableViewClick={() => setIsShTableView(true)}
        onCardViewClick={() => setIsShTableView(false)}
      />
      <div className="sh-card-table-switch">
        {isShTableView ? (
          <ClientTable
            columns={columns}
            data={getGlFilteredData()}
            itemsPerPage={5}
            renderTableCell={renderShTableCell}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                descriptionHeaderText={item.description}
                descriptionBodyText={item.ie}
                pageName={"StakeHolder"}
                influence={item.influence}
                power={item.power}
                stakeHolderType={item.typeName}
                handleEdit={() => handleEdit(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isShTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      {isModalOpen && (
        <StakeHolder
          onClose={handleCloseModal}
          setName={modalTitle}
          handleStatusChange={() => {}}
          handleDoneClick={
            modalMode === "add" ? createNewStakeHolderApi : updateStakeHolderApi
          }
          modalMode={modalMode}
          NameLabel={t("Name")}
          TypeLabel={t("Type")}
          IELabel={t("IELabel")}
          InfluenceLabel={t("InfluenceLabel")}
          PowerLabel={t("PowerLabel")}
          DescriptionLabel={t("Description")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("AddToList")}
          updateButtonLabel={t("Update")}
          selectedRow={selectedRow}
          typeOptions={typeOptions}
          ieOptions={ieOptions}
          onDeleteClick={handleDeleteOpenModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default Stakeholders;
