import React, { useState, useEffect, useCallback } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  fetchCountries,
  fetchSectors,
} from "../../features/slices/ProjectScreens";
import { Back } from "../../constants/icons/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

const NewProject = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [formFields, setFormFields] = useState(newProjectFormFields);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isError: isProjectCreateError, errorMessage } = useSelector(
    (state) => state.projectDetails
  );
  const { instance, inProgress } = useMsal();

  const email = location.state?.email;
  const userId = location.state?.userId;
  const name = location.state?.name;
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");

  useEffect(() => {
    if (token) {
      fetchData(dispatch, fetchCountries, setCountryList, token);
      fetchData(dispatch, fetchSectors, setSectorList, token);
    }
  }, [inProgress, dispatch, token, instance]);

  useEffect(() => {
    updateFormFields(countryList, "countryId", setFormFields);
    updateFormFields(sectorList, "clientSector", setFormFields);
  }, [countryList, sectorList]);

  useEffect(() => {
    if (name && email && userId) {
      setFormState((prevState) => ({
        ...prevState,
        projectAdmin: [{ id: userId, displayName: name, emailId: email }],
      }));
    }
  }, [name, email, userId]);

  const handleBack = useCallback(() => navigate("/"), [navigate]);

  const isFormValid = useCallback(() => {
    const {
      projectName,
      clientName,
      countryId,
      channel,
      projectSize,
      clientSector,
      projectAdmin,
    } = formState;
    return (
      projectName.trim() &&
      clientName.trim() &&
      countryId > 0 &&
      channel.trim() &&
      projectSize.trim() &&
      clientSector > 0 &&
      projectAdmin.length > 0
    );
  }, [formState]);

  const handleSubmit = useCallback(() => {
    if (isFormValid()) {
      setIsSubmitting(true);
      const projectData = createProjectData(formState);
      dispatch(createProject({ projectData, token }))
        .unwrap()
        .then((response) => {
          if (response.success && response.data) {
            navigate(`/project-home/${response.data}`, { state: { token } });
          } else {
            alert("Project created, but no data received to navigate.");
          }
        })
        .catch((error) => console.error("Creation failed:", error))
        .finally(() => setIsSubmitting(false));
    } else {
      alert("Please fill in all required fields.");
    }
  }, [formState, isFormValid, dispatch, token, navigate]);

  const handleError = useCallback(() => navigate("/"), [navigate]);

  if (isProjectCreateError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="Container">
        <div id="inline">
          <MotifButton onClick={handleBack} className="button done-button">
            <Back />
            &nbsp;
            {t("backButton")}
          </MotifButton>
        </div>
        <MotifCard
          orientation="horizontal"
          variant="card-border"
          className="new-project-card"
        >
          {isSubmitting && (
            <MotifProgressLoader className="loader" show variant="default" />
          )}
          <MotifCardHeader>
            <span className="motif-h6-default-regular">{t("newProject")}</span>
          </MotifCardHeader>
          <MotifCardBody>
            <NewProjectForm
              formFields={formFields}
              formState={formState}
              setFormState={setFormState}
              setSelectedUsers={setSelectedUsers}
            />
          </MotifCardBody>
          <MotifCardFooter className="new-project-footer">
            <MotifButton onClick={handleError} className="button cancel-button">
              {t("Cancel")}
            </MotifButton>
            <MotifButton
              className="button done-button"
              onClick={handleSubmit}
              disabled={!isFormValid() || isSubmitting}
            >
              {t("submitButton")}
            </MotifButton>
          </MotifCardFooter>
        </MotifCard>
      </div>
    </div>
  );
};

const initialFormState = {
  projectName: "",
  clientName: "",
  engagementCode: "",
  gisId: null,
  opportunity: "",
  country: "",
  channel: "",
  projectSize: "",
  clientSector: "",
  projectAdmin: [],
};
const fetchData = (dispatch, action, setState, token) => {
  dispatch(action({ token })).then((response) => {
    if (response.payload) {
      setState(response.payload);
    }
  });
};

const updateFormFields = (list, fieldId, setFormFields) => {
  if (list?.data) {
    setFormFields((prevFields) =>
      prevFields.map((field) => {
        if (field.id === fieldId) {
          return {
            ...field,
            options: list.data.map((item) => ({
              value: item.id,
              label:
                fieldId === "clientSector"
                  ? `${item.name} - ${item.subName}`
                  : item.name,
            })),
          };
        }
        return field;
      })
    );
  } else {
    console.error(`${fieldId} data is not available`);
  }
};

const createProjectData = (formState) => ({
  name: formState.projectName,
  clientName: formState.clientName,
  engagementCode: formState.engagementCode,
  countryId: formState.countryId,
  isChannel1: formState.channel === "yes",
  size: formState.projectSize,
  adminUsers: formState.projectAdmin.map((admin) => ({
    aadId: admin.id,
    name: admin.displayName,
    email: admin.emailId,
    projectRoleId: 2,
  })),
  statusId: 1,
  gisId: parseInt(formState.gisId),
  opportunity: formState.opportunity,
  sectorId: formState.clientSector,
});

export default NewProject;
