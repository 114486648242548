import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_SOURCES_TYPES_URL,
  GET_ALL_SOURCES_URL,
  CREATE_SOURCES_URL,
  UPDATE_SOURCES_URL,
  DELETE_SOURCES_URL,
} from "../../services/Api";

// Constants for action types
const FETCH_SOURCES = "dmaSources/fetchSources";
const FETCH_SOURCES_TYPES = "dmaSources/fetchSourcesTypes";
const CREATE_SOURCES = "dmaSources/createSources";
const UPDATE_SOURCES = "dmaSources/updateSources";
const DELETE_SOURCES = "dmaSources/deleteSources";

// Common error handler
const handleError = (error) => {
  console.error(error);
  return (
    error.response?.data?.message ||
    error.response?.data?.title ||
    error.message ||
    "An error occurred"
  );
};

// Helper function for API calls
const fetchData = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
};

// Async thunks
export const fetchSources = createAsyncThunk(
  FETCH_SOURCES,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      "get",
      `${GET_ALL_SOURCES_URL}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const fetchSourcesTypes = createAsyncThunk(
  FETCH_SOURCES_TYPES,
  async ({ token }, { rejectWithValue }) => {
    return fetchData(
      "get",
      GET_ALL_SOURCES_TYPES_URL,
      null,
      token,
      rejectWithValue
    );
  }
);

export const createSources = createAsyncThunk(
  CREATE_SOURCES,
  async ({ moduleId, sourceData, token }, { rejectWithValue }) => {
    return fetchData(
      "post",
      `${CREATE_SOURCES_URL}/${moduleId}`,
      sourceData,
      token,
      rejectWithValue
    );
  }
);

export const updateSources = createAsyncThunk(
  UPDATE_SOURCES,
  async ({ sourceId, sourceData, moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      "put",
      `${UPDATE_SOURCES_URL}/${sourceId}/${moduleId}`,
      sourceData,
      token,
      rejectWithValue
    );
  }
);

export const deleteSources = createAsyncThunk(
  DELETE_SOURCES,
  async ({ sourceId, moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      "delete",
      `${DELETE_SOURCES_URL}/${sourceId}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  errorMessage: "",
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const dmaSourcesSlice = createSlice({
  name: "dmaSources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, handlePending)
      .addCase(fetchSources.fulfilled, handleFulfilled)
      .addCase(fetchSources.rejected, handleRejected)
      .addCase(fetchSourcesTypes.pending, handlePending)
      .addCase(fetchSourcesTypes.fulfilled, handleFulfilled)
      .addCase(fetchSourcesTypes.rejected, handleRejected)
      .addCase(createSources.pending, handlePending)
      .addCase(createSources.fulfilled, handleFulfilled)
      .addCase(createSources.rejected, handleRejected)
      .addCase(updateSources.pending, handlePending)
      .addCase(updateSources.fulfilled, handleFulfilled)
      .addCase(updateSources.rejected, handleRejected)
      .addCase(deleteSources.pending, handlePending)
      .addCase(deleteSources.fulfilled, handleFulfilled)
      .addCase(deleteSources.rejected, handleRejected);
  },
});

export default dmaSourcesSlice.reducer;
