import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Card, Grid, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IROAssessmentSubheader from "../../../components/iroAssessmentSubheader/IROAssessmentSubheader";
import {
  getDGADataPointQuery,
  getMaturityLevels,
} from "../../../features/slices/DGAContext";
import { fetchClientsDetails } from "../../../features/slices/ProjectScreens";
import { Edit, DgaAR } from "../../../constants/icons/Icons";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DgaAssessmentModal from "../../../components/modals/dmaModals/DgaAssessmentModals";
import { MotifLabel, MotifSelect, MotifOption } from "@ey-xd/motif-react";
import TextField from "@mui/material/TextField";
import MappingForm from "../../../components/forms/MappingForm";

const AssessmentCard = ({ item, onEdit }) => (
<Grid item xs={12} key={item.id}>
  <Card
    variant="outlined"
    sx={{ display: "flex", alignItems: "center", padding: 2 }}
  >
    <Box flex={1}>
      <Typography
        variant="subtitle1"
        sx={{ color: " #2E2E38", marginBottom: 2 }}
      >
        <Box component="span" fontWeight="bold">
          {item.esrs}
        </Box>
        {" - "}
        <Box component="span" fontWeight="normal">
          {item.name}
        </Box>
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ffffff",
          color: "#724BC3",
          textTransform: "none",
        }}
        size="small"
      >
        Narrative
      </Button>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ffffff",
          color: "#724BC3",
          marginLeft: "10px",
        }}
        size="small"
      >
        CL
      </Button>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton>
        <DgaAR />
      </IconButton>
      <IconButton onClick={() => onEdit(item)}>
        <Edit />
      </IconButton>
    </Box>
  </Card>
</Grid>
);

const AssessmentDga = () => {
  const dispatch = useDispatch();
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maturityLevels, setMaturityLevels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEsrs, setSelectedEsrs] = useState("");
  const [selectedDr, setSelectedDr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({});
  const [raciDropdownData, setRaciDropdownData] = useState([]);
  const [initialData, setInitialData] = useState(null);

  const itemsPerPage = 5;
  const token =
    useSelector((state) => state.user.jwtToken) ||
    document.cookie.match(new RegExp(`(^| )authToken=([^;]+)`))?.[2];
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [dataResult, maturityLevelsResult, clientsDetailsResult] =
          await Promise.all([
            dispatch(getDGADataPointQuery({ moduleId, token })).unwrap(),
            dispatch(getMaturityLevels({ token })).unwrap(),
            dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
          ]);
        setData(dataResult?.data);
        setMaturityLevels(maturityLevelsResult?.data);

        if (Array.isArray(clientsDetailsResult?.data)) {
          const clientsData = clientsDetailsResult?.data.map((item) => ({
            ...item,
            typeName: item.clientOrganisationType?.name || "Unknown",
          }));
          setRaciDropdownData(clientsData);
        } else {
          setRaciDropdownData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, moduleId, token, projectId]);

  const uniqueEsrs = useMemo(
    () => [...new Set(data.map((item) => item.esrs))],
    [data]
  );
  const uniqueDr = useMemo(
    () => [...new Set(data.map((item) => item.dr))],
    [data]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedEsrs, selectedDr]);

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return (
        (selectedEsrs ? item.esrs === selectedEsrs : true) &&
        (selectedDr ? item.dr === selectedDr : true)
      );
    });
  }, [data, selectedEsrs, selectedDr]);

  const paginatedData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
//All tabs
  const tabs = [
    {
      label: "GAP",
      content: (
        <div>
          <div style={{ marginBottom: "20px" }}>
            <MotifLabel className="iro-label">Maturity level</MotifLabel>
            <MotifSelect
              value={selectedItem?.gapId}
              className="iro-textField"
              onChange={(e) => handleChange("gapId", e)}
            >
              {maturityLevels.map((item) => (
                <MotifOption key={item.id} value={item.id}>
                  {item.name}
                </MotifOption>
              ))}
            </MotifSelect>
          </div>
          <div>
            <MotifLabel className="iro-label">Description of GAP</MotifLabel>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              value={formData["gapDescription"]}
              onChange={(e) => handleChange("gapDescription", e.target.value)}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Documentation",
      content: (
        <div>
          <MotifLabel className="iro-label">Description</MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={formData["documentationDescription"]}
            onChange={(e) =>
              handleChange("documentationDescription", e.target.value)
            }
          />
        </div>
      ),
    },
    {
      label: "Source of data",
      content: (
        <div>
          <MotifLabel className="iro-label">Description</MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={formData["sourceOfDataDescription"]}
            onChange={(e) =>
              handleChange("sourceOfDataDescription", e.target.value)
            }
          />
        </div>
      ),
    },
    {
      label: "Completeness",
      content: (
        <div>
          <MotifLabel className="iro-label">Description</MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={formData["completenessDescription"]}
            onChange={(e) =>
              handleChange("completenessDescription", e.target.value)
            }
          />
        </div>
      ),
    },
    {
      label: "RACI",
      content: (
        <MappingForm
          responsibleLabel={t("Responsible")}
          accountableLabel={t("Accountable")}
          consultedLabel={t("Consulted")}
          informedLabel={t("Informed")}
          othersLabel={t("Others")}
          selectResponsilbleLabel={t("SelectResponsible")}
          selectAccountableLabel={t("SelectAccountable")}
          selectConsultedLabel={t("SelectConsulted")}
          selectInformedLabel={t("SelectInformed")}
          selectOthersLabel={t("SelectOthers")}
          RACIMapping={t("RACIMapping")}
          raciDropdownData={raciDropdownData}
          initialData={initialData}
        />
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <Box sx={{ margin: "auto", padding: "10px" }}>
        <IROAssessmentSubheader
          header={t("ESRS General disclosures")}
          subHeader={t(
            "General basis for preparation of the sustainability statement"
          )}
          previousButtonLabel="Disclosure requirement"
          SelectESRS="ESRS"
          SelectDisclosure="Disclosure requirement"
          EsrsArray={uniqueEsrs}
          GeneralDisclosureArray={uniqueDr}
          setEsrs={setSelectedEsrs}
          setGeneralDisclosure={setSelectedDr}
        />
      </Box>
      <Box sx={{ maxWidth: "1400px", margin: "auto", padding: "10px" }}>
        <Grid container spacing={3}>
          {paginatedData.map((item) => (
            <AssessmentCard
              key={item.id}
              item={item}
              onEdit={handleEditClick}
            />
          ))}
        </Grid>

        {/* Pagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNextPage}
            disabled={paginatedData.length < itemsPerPage}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>

      {isModalOpen && (
        <DgaAssessmentModal
          onClose={() => setIsModalOpen(false)}
          modalTitle="Assessment"
          tabs={tabs}
          saveButtonLabel="Save"
          cancelButtonLabel="Cancel"
          disable={false}
          onSave={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AssessmentDga;
