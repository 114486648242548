import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifRadioButton,
  MotifToggleSwitch,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./SustainabilityMatters.scss";
import { useTranslation } from "react-i18next";

const SustainabilityMattersModal = ({
  onClose,
  modalMode,
  modalTitle,
  topicNameLabel,
  parentLabel,
  esrsAr16Label,
  esgLabel,
  enterTopicNameLabel,
  selectParentLabel,
  selectEsrsAr16Label,
  isHumanRightsRelatedLabel,
  DescriptionLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  esrsar16Options,
  parentOptions,
  handleDoneClick,
  filteredUpdateData,
  onDeleteClick,
  DeleteLabel,
  updateItemID,
}) => {
  const isEditMode = modalMode === "edit";
  const [selectedTab, setSelectedTab] = useState("Definition");

  const [esrsAr16Error, setEsrsAr16Error] = useState("");
  const [topicNameError, setTopicNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  // Maximum character limit
  const MAX_CHAR_LIMIT = 3000;

  const [formData, setFormData] = useState({
    topicName: "",
    parent: "",
    esrsAr16: "",
    esg: "E",
    description: "",
    humanRightsToggle: false,
  });
  const { topicName, parent, esrsAr16, esg, description, humanRightsToggle } =
    formData;
  const { t } = useTranslation();

  useEffect(() => {
    if (filteredUpdateData && isEditMode) {
      setFormData({
        topicName: filteredUpdateData.label || "",
        parent: filteredUpdateData.parentId || "",
        esrsAr16: parseFloat(filteredUpdateData.esrsaR16.id) || 0,
        description: filteredUpdateData.description,
        esg: filteredUpdateData.esg,
        humanRightsToggle: filteredUpdateData.isHumanRightsRelated,
      });
    }
  }, [filteredUpdateData, isEditMode]);

  useEffect(() => {
    validateFields();
  }, [formData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const changeToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      humanRightsToggle: !prevData.humanRightsToggle,
    }));
  };

  const validateFields = () => {
    let isValid = true;

    if (!esrsAr16) {
      setEsrsAr16Error("EsrsAr16 is required");
      isValid = false;
    } else {
      setEsrsAr16Error("");
    }

    if (!description) {
      setDescriptionError("Description is required");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    if (!topicName) {
      setTopicNameError("Topic name is required");
      isValid = false;
    } else {
      setTopicNameError("");
    }

    if (description?.length >= MAX_CHAR_LIMIT) {
      setDescriptionError(
        `Description should not exceed ${MAX_CHAR_LIMIT} characters`
      );
      isValid = false;
    } else {
      setDescriptionError("");
    }

    setIsFormValid(isValid);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const data = {
        sustainabilityMatterId: 1100000,
        parentId: parent || null,
        esrsaR16Id: esrsAr16,
        name: topicName,
        esg,
        description: description || "",
        aggregatedGroupName: "string",
        isRelevant: true,
        explanationOfRelevance: "string",
        sortOrder: 0,
        isHumanRightsRelated: humanRightsToggle,
      };

      handleDoneClick(data);
      onClose();
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Description":
        return (
          <>
            <div className="form-row sustain-description-label">
              <MotifLabel id="select-label" data-testid="description-label">
                {DescriptionLabel}*
              </MotifLabel>
              <TextField
                placeholder=""
                multiline
                fullWidth
                rows={4}
                value={description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
              {descriptionError && (
                <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
              )}
            </div>
          </>
        );
      case "Definition":
        return (
          <>
            <div className="form-row">
              <div className="form-group">
                <MotifMessage data-testid="topic-name">
                  {topicNameLabel}*
                </MotifMessage>
                <MotifFormField>
                  <MotifInput
                    placeholder={enterTopicNameLabel}
                    value={topicName}
                    onChange={(e) =>
                      handleInputChange("topicName", e.target.value)
                    }
                  />
                  {topicNameError && (
                    <MotifErrorMessage>{topicNameError}</MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
              <div className="form-group">
                <MotifMessage>{parentLabel}</MotifMessage>
                <MotifFormField>
                  <MotifLabel>{selectParentLabel}</MotifLabel>
                  <MotifSelect
                    value={parent}
                    onChange={(e) => handleInputChange("parent", e)}
                  >
                    {parentOptions
                      .filter((option) => option.value !== updateItemID) // don't need to show self value in parent
                      .map((type) => (
                        <MotifOption key={type.value} value={type.value}>
                          {type.label}
                        </MotifOption>
                      ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <MotifMessage data-testid="esrs-ar16">
                  {esrsAr16Label}*
                </MotifMessage>
                <MotifFormField>
                  <MotifLabel>{selectEsrsAr16Label}</MotifLabel>
                  <MotifSelect
                    value={esrsAr16}
                    onChange={(e) => handleInputChange("esrsAr16", e)}
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {esrsar16Options.map((type) => (
                      <MotifOption key={type.value} value={type.value}>
                        {type.label}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                  {esrsAr16Error && (
                    <MotifErrorMessage>{esrsAr16Error}</MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group half-width">
                <MotifMessage data-testid="esg">{esgLabel}*</MotifMessage>
                <div className="radio-group">
                  <MotifFormField>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="E"
                      checked={esg === "E"}
                    >
                      E
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="S"
                      className="margin-left-radio"
                      checked={esg === "S"}
                    >
                      S
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="G"
                      className="margin-left-radio"
                      checked={esg === "G"}
                    >
                      G
                    </MotifRadioButton>
                  </MotifFormField>
                </div>
              </div>
              <div className="form-group half-width">
                <MotifMessage data-testid="human-rights">
                  {isHumanRightsRelatedLabel}*
                </MotifMessage>
                <MotifFormField className="human-rights-toggle">
                  <MotifToggleSwitch
                    onChange={changeToggle}
                    id="exampleToggle"
                    hideLabels={true}
                    checked={humanRightsToggle}
                  />
                </MotifFormField>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Definition" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Definition")}
                >
                  {t("Definition")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Description" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Description")}
                >
                  {t("TabDescription")}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="button delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              className="button done-button"
              disabled={!isFormValid}
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

SustainabilityMattersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  topicNameLabel: PropTypes.string.isRequired,
  parentLabel: PropTypes.string.isRequired,
  esrsAr16Label: PropTypes.string.isRequired,
  esgLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  selectParentLabel: PropTypes.string.isRequired,
  selectEsrsAr16Label: PropTypes.string.isRequired,
  isHumanRightsRelatedLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  esrsar16Options: PropTypes.array,
  parentOptions: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  filteredUpdateData: PropTypes.object,
  updateItemID: PropTypes.number,
};

export default SustainabilityMattersModal;
