import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_VALUE_CHAIN_TYPES,
  CREATE_VALUE_CHAIN,
  UPDATE_VALUE_CHAIN,
  DELETE_VALUE_CHAIN,
  GET_VALUE_CHAIN,
} from "../../services/Api";

// Common error handler
const handleError = (error, rejectWithValue) => {
  console.error(error.message);
  return rejectWithValue(
    error.response?.data?.message ||
      error.response?.data?.title ||
      error.message ||
      "An error occurred"
  );
};

// Common request handler
const makeRequest = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return handleError(error, rejectWithValue);
  }
};

// Async thunks
export const fetchValueChainTypes = createAsyncThunk(
  "dmaValueChain/fetchValueChainTypes",
  async (token, { rejectWithValue }) => {
    return makeRequest(
      "get",
      GET_VALUE_CHAIN_TYPES,
      null,
      token,
      rejectWithValue
    );
  }
);

export const fetchValueChain = createAsyncThunk(
  "dmaValueChain/fetchValueChain",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return makeRequest(
      "get",
      `${GET_VALUE_CHAIN}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const createNewValueChain = createAsyncThunk(
  "dmaValueChain/createNewValueChain",
  async ({ moduleId, valueChainData, token }, { rejectWithValue }) => {
    return makeRequest(
      "post",
      `${CREATE_VALUE_CHAIN}/${moduleId}`,
      valueChainData,
      token,
      rejectWithValue
    );
  }
);

export const updateValueChain = createAsyncThunk(
  "dmaValueChain/updateValueChain",
  async (
    { valueChainId, moduleId, valueChainData, token },
    { rejectWithValue }
  ) => {
    return makeRequest(
      "put",
      `${UPDATE_VALUE_CHAIN}/${valueChainId}/${moduleId}`,
      valueChainData,
      token,
      rejectWithValue
    );
  }
);

export const deleteValueChain = createAsyncThunk(
  "dmaValueChain/deleteValueChain",
  async ({ valueChainId, moduleId, token }, { rejectWithValue }) => {
    return makeRequest(
      "delete",
      `${DELETE_VALUE_CHAIN}/${valueChainId}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
  state.isError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

// Slice definition
const dmaValueChainSlice = createSlice({
  name: "dmaValueChain",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
    postSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchValueChainTypes.pending, handlePending)
      .addCase(fetchValueChainTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
        state.isError = false;
      })
      .addCase(fetchValueChainTypes.rejected, handleRejected)
      .addCase(fetchValueChain.pending, handlePending)
      .addCase(fetchValueChain.fulfilled, handleFulfilled)
      .addCase(fetchValueChain.rejected, handleRejected)
      .addCase(createNewValueChain.pending, (state) => {
        handlePending(state);
        state.postSuccess = false;
      })
      .addCase(createNewValueChain.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.postSuccess = true;
      })
      .addCase(createNewValueChain.rejected, (state, action) => {
        handleRejected(state, action);
        state.postSuccess = false;
      })
      .addCase(updateValueChain.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(updateValueChain.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(updateValueChain.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })
      .addCase(deleteValueChain.pending, (state) => {
        handlePending(state);
        state.postSuccess = false;
      })
      .addCase(deleteValueChain.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.postSuccess = true;
      })
      .addCase(deleteValueChain.rejected, (state, action) => {
        handleRejected(state, action);
        state.postSuccess = false;
      });
  },
});

export default dmaValueChainSlice.reducer;
