import React, { useState } from "react";
import "./SessionAccordion.scss";
import { View, Edit } from "../../../constants/icons/Icons";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Accordion({
  data,
  isAdmin,
  handleModuleModal,
  handleEditSessionModal,
  handleDeleteSessionModal,
  handleEditModuleModal,
  ModuleTitleEN,
  projectName,
  sessionData,
  projectId,
}) {
  return (
    <div>
      {data.map((item) => (
        <AccordionItem
          key={item?.id}
          id={item?.id}
          title={item.name}
          frameworkId={item?.framework?.id}
          content={item?.modules}
          isAdmin={isAdmin}
          handleModuleModal={() => handleModuleModal(item?.name, item?.id)}
          handleEditSessionModal={handleEditSessionModal}
          handleDeleteSessionModal={handleDeleteSessionModal}
          handleEditModuleModal={handleEditModuleModal}
          ModuleTitleEN={ModuleTitleEN}
          projectName={projectName}
          sessionData={sessionData}
          projectId={projectId}
        />
      ))}
    </div>
  );
}

function AccordionItem({
  id,
  title,
  frameworkId,
  content = [],
  isAdmin,
  handleModuleModal,
  handleEditSessionModal,
  handleEditModuleModal,
  ModuleTitleEN,
  projectName,
  sessionData,
  projectId, // Add projectId here
}) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const handleNavigate = (item) => {
    const routes = {
      DMA: `/dma/${item.id}`,
      DeepDive: `/dd/${item.id}`,
      "DGA (Datapoint Gap Assessment)": `/dga/${item.id}`,
    };
    navigate(routes[item.moduleTypeName], {
      state: { token, projectName, sessionData, projectId },
    });
  };
  const toggleAccordion = () => {
    setIsOpen(true);
  };

  return (
    <div className="accordion-item" data-testid="accordion-item">
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <MotifAccordion
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={toggleAccordion}
          style={{
            flex: "1",
          }}
          variant="editorial"
        >
          <MotifAccordionTrigger onClick={toggleAccordion}>
            <div className="trigger-content">
              {title}
              {isAdmin && (
                <div className="module">
                  <div className="session-top-icons">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditSessionModal(id, title, frameworkId);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </MotifAccordionTrigger>
          {isOpen && (
            <MotifAccordionContent>
              <table>
                <tbody>
                  {content.map((item) => (
                    <tr key={item.id}>
                      <td className="session-table-data">
                        {item.name} - {item.moduleTypeName}
                        <div className="session-accordion-icons">
                          <div className="icon-container-session">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleNavigate(item);
                              }}
                            >
                              <View />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditModuleModal(item.id, title);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button
                className="module-link"
                onClick={(e) => {
                  e.preventDefault();
                  handleModuleModal();
                }}
                type="button"
              >
                {ModuleTitleEN} &nbsp;&nbsp;
              </button>
            </MotifAccordionContent>
          )}
        </MotifAccordion>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  isAdmin: PropTypes.bool,
  data: PropTypes.array.isRequired,
  handleModuleModal: PropTypes.func.isRequired,
  handleEditSessionModal: PropTypes.func.isRequired,
  handleDeleteSessionModal: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  sessionData: PropTypes.array,
  ModuleTitleEN: PropTypes.string.isRequired,
  handleEditModuleModal: PropTypes.func,
};

AccordionItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  projectName: PropTypes.string,
  frameworkId: PropTypes.number,
  content: PropTypes.array,
  sessionData: PropTypes.array,
  isAdmin: PropTypes.bool,
  handleModuleModal: PropTypes.func.isRequired,
  handleEditSessionModal: PropTypes.func.isRequired,
  handleDeleteSessionModal: PropTypes.func.isRequired,
  handleEditModuleModal: PropTypes.func,
  ModuleTitleEN: PropTypes.string.isRequired,
};

export default Accordion;
