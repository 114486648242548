import React, { useState, useEffect, useMemo, useCallback } from "react";
import WaterfallChart from "../../../components/charts/WaterfallChart";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getIdentifiedImpacts,
  getIdentifiedRiskAndOpportunity,
  getMaterialIdentifiedImpacts,
  getMaterialIdentifiedRiskAndOpportunity,
} from "../../../features/slices/DMAReporting";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import "./DMAReporting.scss"; // Import the stylesheet

const DMAReporting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) || getCookie("authToken");
  const { t } = useTranslation();

  // State for each data type
  const [impactData, setImpactData] = useState([]);
  const [materialImpactData, setMaterialImpactData] = useState([]);
  const [riskOpportunityData, setRiskOpportunityData] = useState([]);
  const [materialRiskOpportunityData, setMaterialRiskOpportunityData] =
    useState([]);
  const [error, setError] = useState(null);

  // Memoize the fetchData function
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await Promise.all([
        dispatch(getIdentifiedImpacts({ moduleId, token })),
        dispatch(getMaterialIdentifiedImpacts({ moduleId, token })),
        dispatch(getIdentifiedRiskAndOpportunity({ moduleId, token })),
        dispatch(getMaterialIdentifiedRiskAndOpportunity({ moduleId, token })),
      ]);

      const [
        impactResponse,
        materialImpactsResponse,
        riskOpportunityResponse,
        materialRiskOpportunityResponse,
      ] = results;

      setImpactData(
        impactResponse?.payload?.data?.moduleSustainabilityMatters || []
      );
      setMaterialImpactData(materialImpactsResponse?.payload?.data || []);
      setRiskOpportunityData(
        riskOpportunityResponse?.payload?.data?.moduleSustainabilityMatters ||
          []
      );
      setMaterialRiskOpportunityData(
        materialRiskOpportunityResponse?.payload?.data || []
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token]);

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    let isMounted = true;

    const fetchDataSafe = async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all([
          dispatch(getIdentifiedImpacts({ moduleId, token })),
          dispatch(getMaterialIdentifiedImpacts({ moduleId, token })),
          dispatch(getIdentifiedRiskAndOpportunity({ moduleId, token })),
          dispatch(getMaterialIdentifiedRiskAndOpportunity({
            moduleId,
            token,
          })),
        ]);

        if (isMounted) {
          const [
            impactResponse,
            materialImpactsResponse,
            riskOpportunityResponse,
            materialRiskOpportunityResponse,
          ] = results;

          setImpactData(
            impactResponse?.payload?.data?.moduleSustainabilityMatters || []
          );
          setMaterialImpactData(materialImpactsResponse?.payload?.data || []);
          setRiskOpportunityData(
            riskOpportunityResponse?.payload?.data
              ?.moduleSustainabilityMatters || []
          );
          setMaterialRiskOpportunityData(
            materialRiskOpportunityResponse?.payload?.data || []
          );
        }
      } catch (error) {
        if (isMounted) setError("Failed to fetch data. Please try again.");
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    fetchDataSafe();

    return () => {
      isMounted = false; // Prevent state updates after unmount
    };
  }, [dispatch, moduleId, token]);

  // Memoize the chart data
  const memoizedImpactData = useMemo(() => impactData, [impactData]);
  const memoizedMaterialImpactData = useMemo(
    () => materialImpactData,
    [materialImpactData]
  );
  const memoizedRiskOpportunityData = useMemo(
    () => riskOpportunityData,
    [riskOpportunityData]
  );
  const memoizedMaterialRiskOpportunityData = useMemo(
    () => materialRiskOpportunityData,
    [materialRiskOpportunityData]
  );

  const ChartRow = useCallback(
    ({ title1, title2, data1, data2 }) => (
      <div className="motif-row dma-reporting-row">
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title1}</h3>
          <WaterfallChart data={data1} />
        </div>
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title2}</h3>
          <WaterfallChart data={data2} />
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {error && <div className="error-message">{error}</div>}
      {!isLoading && !error && (
        <div>
          <ChartRow
            title1={t("IdentifiedImpactsByTopic")}
            title2={t("MaterialImpactsByTopic")}
            data1={memoizedImpactData}
            data2={memoizedMaterialImpactData}
          />
          <ChartRow
            title1={t("IdentifiedRiskandOpportunityByTopic")}
            title2={t("MaterialRiskandOpportunityByTopic")}
            data1={memoizedRiskOpportunityData}
            data2={memoizedMaterialRiskOpportunityData}
          />
        </div>
      )}
    </>
  );
};

export default DMAReporting;
