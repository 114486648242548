import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_SUSTAINABILITY_MATTERS,
  CREATE_SUSTAINABILITY_MATTERS,
  UPDATE_SUSTAINABILITY_MATTERS,
  DELETE_SUSTAINABILITY_MATTERS,
  GET_ESRS_URL,
  GET_ESRS_AR16_URL,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (url, method, data, token) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  const response = await axios(config);
  return response.data;
};

// Generic thunk creator
const createThunk = (type, apiCall) => {
  return createAsyncThunk(type, async (params, { rejectWithValue }) => {
    try {
      return await apiCall(params);
    } catch (error) {
      console.error(`Error in ${type}:`, error);
      return rejectWithValue(
        error.response?.data?.message || error.message || `Error in ${type}`
      );
    }
  });
};

export const getAllSustainabilityMatters = createThunk(
  "getAllSustainabilityMatters",
  ({ moduleId, token }) =>
    apiRequest(
      `${GET_ALL_SUSTAINABILITY_MATTERS}/${moduleId}`,
      "get",
      null,
      token
    )
);

export const createNewSustainabilityMatters = createThunk(
  "createNewSustainabilityMatters",
  ({ moduleId, sustainabilityMattersData, token }) =>
    apiRequest(
      `${CREATE_SUSTAINABILITY_MATTERS}/${moduleId}`,
      "post",
      sustainabilityMattersData,
      token
    )
);

export const updateSustainabilityMatters = createThunk(
  "updateSustainabilityMatters",
  ({ sustainabilityMattersId, moduleId, sustainabilityMattersData, token }) =>
    apiRequest(
      `${UPDATE_SUSTAINABILITY_MATTERS}/${sustainabilityMattersId}/${moduleId}`,
      "put",
      sustainabilityMattersData,
      token
    )
);

export const deleteSustainabilityMatters = createThunk(
  "deleteSustainabilityMatters",
  ({ sustainabilityMattersId, moduleId, token }) =>
    apiRequest(
      `${DELETE_SUSTAINABILITY_MATTERS}/${sustainabilityMattersId}/${moduleId}`,
      "delete",
      null,
      token
    )
);

export const fetchEsrs = createThunk("fetchEsrs", ({ moduleId, token }) =>
  apiRequest(`${GET_ESRS_URL}/?moduleId=${moduleId}`, "get", null, token)
);

export const fetchEsrsAr16 = createThunk("fetchEsrsAr16", ({ token }) =>
  apiRequest(GET_ESRS_AR16_URL, "get", null, token)
);

// Utility function to handle extra reducers
const handleAsyncActions = (builder, action, successKey = "data") => {
  builder
    .addCase(action.pending, (state) => {
      state.isLoading = true;
      state.postSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(action.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.postSuccess = true;
      state[successKey] = payload;
      state.isError = false;
    })
    .addCase(action.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.postSuccess = false;
      state.isError = true;
      state.errorMessage = payload;
    });
};

// Slice definition
const dmaSustainabilityMattersSlice = createSlice({
  name: "dmaSustainabilityMatters",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getAllSustainabilityMatters, "data");
    handleAsyncActions(builder, createNewSustainabilityMatters, "data");
    handleAsyncActions(builder, updateSustainabilityMatters, "data");
    handleAsyncActions(builder, deleteSustainabilityMatters, "data");
    handleAsyncActions(builder, fetchEsrs, "data");
    handleAsyncActions(builder, fetchEsrsAr16, "data");
  },
});

export default dmaSustainabilityMattersSlice.reducer;
